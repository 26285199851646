import { CardHelper } from "../../Helpers/CardHelper";
import { Card } from "../Card";
import { GenerationConfig } from "../GenerationConfig";
import { CardsMeta } from "./CardsMeta";

export class GeneratedKingdomMeta {

    public get Kingdom() { return this._kingdom; }
    private readonly _kingdom: Card[];

    public get RequiredOther() { return this._requiredOther; }
    private readonly _requiredOther: { cardTypes: string[], portrait: Card[], landscape: Card[], };

    public get AllCards() { return this._allCards; }
    private readonly _allCards: Card[];

    public get Setup() { return this._setup; }
    private readonly _setup: string[];

    public get GenerationLogs() { return this._generationLogs; }
    private readonly _generationLogs: string[];

    public get GenerationConfig() { return this._generationConfig; }
    private readonly _generationConfig: GenerationConfig;

    constructor(cards: CardsMeta, kingdom: Card[], optional: Card[], config: GenerationConfig, generationLogs: string[]) {

        this._generationConfig = config;
        this._generationLogs = generationLogs;

        this._kingdom = kingdom.sort((a, b) => {
            if (a.cost === b.cost) {

                if (a.set === b.set) {
                    return a.name < b.name ? -1 : 1;
                }

                return a.set > b.set ? -1 : 1;
            }

            return a.cost > b.cost ? 1 : -1;
        });

        const requiredOther = cards.getRequiredOtherCardsForCards(this._kingdom, optional);

        const withLandscape = requiredOther.cards.map(x => {
            return {
                card: x,
                landscape: CardHelper.isLandscapeOrientation(x)
            }
        });

        const landscape = withLandscape.filter(x => x.landscape).map(x => x.card)
            .sort((a, b) => a.cost < b.cost ? -1 : 1);

        const portrait = withLandscape.filter(x => !x.landscape).map(x => x.card)
            .sort((a, b) => {

                if (a.set !== b.set) {
                    return a.set < b.set ? -1 : 1;
                }

                const aT = a.types[a.types.length - 1];
                const bT = b.types[b.types.length - 1];

                if (aT !== bT) {
                    return aT < bT ? -1 : 1;
                }

                return a.cost < b.cost ? -1 : 1;
            });

        this._requiredOther = {
            portrait,
            landscape,
            cardTypes: requiredOther.cardTypes.sort((a, b) => a > b ? 1 : -1)
        };

        this._allCards = [
            ...this.Kingdom,
            ...this._requiredOther.portrait,
            ...this._requiredOther.landscape
        ];

        this._setup = this._allCards.map(x => {

            const setup = CardHelper.getSetup(x);

            if (setup !== undefined) {

                const setupStr = setup.join(". ").replaceAll("..", ".");

                return `${x.name}: ${setupStr}`
            }

            return undefined;
        })
            .filter(x => x !== undefined)
            .map(x => x!);
    }
}