import { CardSetHelper } from "../../Helpers/CardSetHelper";
import { GenerationConfigHelper } from "../../Helpers/GenerationConfigHelper";
import { RandomHelper } from "../../Helpers/RandomHelper";
import { Card } from "../Card";
import { CardSet } from "../CardSet";
import { GenerationConfig } from "../GenerationConfig";
import { CardsMeta } from "./CardsMeta";

export class KingdomGenerationContext {

    private readonly _cards: CardsMeta;
    private readonly _generationConfig: GenerationConfig;
    private readonly _logs: string[] = [];

    constructor(cards: CardsMeta, generationConfig: GenerationConfig) {
        this._cards = cards;
        this._generationConfig = generationConfig;
    }

    private _remainingCards: Card[] = [];
    private _generatedCards: Card[] = [];
    // Filler cards can be switched out to satisfy other rules
    private _fillerCards: Card[] = [];

    public generate(): { generatedCards: Card[], logs: string[] } {

        const cardSets = this._cards.getSets(this._generationConfig.sets);

        this._remainingCards = this.getValidCards(cardSets);
        this._generatedCards = [];

        this.findRequiredCards();
        this.findRemainingCards();
        this.findPostRequiredCards();

        return { generatedCards: this._generatedCards, logs: this._logs };
    }

    private findRequiredCards() {

        const configuredRequires = GenerationConfigHelper.getConfiguredRequires(this._generationConfig);
        const requires = RandomHelper.shuffle(configuredRequires);

        for (const require of requires) {

            const existing = this._generatedCards.find(require.canSatisfy);

            if (existing !== undefined) {
                this.log(`${require.name}: already satisfied with ${existing.name}`);
            } else {
                const possible = this._remainingCards.filter(require.canSatisfy);

                const card = this.getRandomCard(possible);

                if (card === undefined) {
                    this.log(`${require.name}: no cards can be used to satisfy`);
                } else {
                    this.log(`${require.name}: satisfied by adding ${card.name}`);
                    this.useCard(card);
                }
            }
        }
    }

    private findRemainingCards() {

        while (this._generatedCards.length < this._generationConfig.kingdomCards) {
            const card = this.getRandomCard(this._remainingCards);

            if (card !== undefined) {
                this.useCard(card);
                this._fillerCards.push(card);
            } else {
                break;
            }
        }

        if (this._fillerCards.length > 0) {
            const str = this._fillerCards.map(x => x.name)
                .sort((a, b) => a > b ? 1 : -1)
                .join(", ");

            this.log(`Using ${this._fillerCards.length} other cards (${str})`);
        }
    }

    private getRandomCard(cards: Card[]) {
        if (cards.length === 0) {
            return undefined;
        }

        const idx = RandomHelper.intInRange(0, cards.length - 1);

        return cards[idx];
    }

    private findPostRequiredCards() {
        const postRequires = GenerationConfigHelper.getConfiguredPostRequires(this._generationConfig);

        for (const postRequire of postRequires) {
            const requiring = postRequire.isNeeded(this._generatedCards);

            if (requiring.length === 0) {
                this.log(`${postRequire.name}: no cards trigger this rule`);
            } else {
                const txt = requiring.map(x => x.name).join(", ");

                this.log(`${postRequire.name}: ${txt}`);

                const alreadySatisfied = this._generatedCards.find(postRequire.canSatisfy);

                if (alreadySatisfied !== undefined) {
                    this.log(`${postRequire.name}: already satisfied by ${alreadySatisfied.name}`);
                } else {

                    const switchable = this.findCardThatCouldBeReplaced(requiring);

                    if (switchable === undefined) {
                        this.log(`${postRequire.name}: no cards can be replaced`);
                    } else {

                        const canSatisfy = this._remainingCards.filter(postRequire.canSatisfy);
                        const toUse = this.getRandomCard(canSatisfy);

                        if (toUse === undefined) {
                            this.log(`${postRequire.name}: no cards can be used to satisfy`);
                        } else {
                            this.replaceCard(postRequire.name, switchable, toUse);
                        }
                    }
                }
            }
        }
    }

    private findCardThatCouldBeReplaced(except: Card[]) {

        const allNames = except.map(x => x.name);
        const cards = this._fillerCards.filter(x => !allNames.includes(x.name));

        return this.getRandomCard(cards);
    }

    private replaceCard(reason: string, cardRemove: Card, cardAdd: Card) {

        this.log(`${reason}: replacing ${cardRemove.name} with ${cardAdd.name}`);

        this.removeCard(cardRemove);
        this.useCard(cardAdd);
    }

    private removeCard(card: Card) {
        this._fillerCards = this._fillerCards.filter(x => x.name !== card.name);
        this._generatedCards = this._generatedCards.filter(x => x.name !== card.name);
    }

    private useCard(card: Card) {
        this._remainingCards = this._remainingCards.filter(x => x.name !== card.name);
        this._generatedCards.push(card);
    }

    private getValidCards(cardSets: CardSet[]) {

        const filters = GenerationConfigHelper.getConfiguredFilters(this._generationConfig);
        const cards = CardSetHelper.getAllCards(cardSets)

        let filteredCards: string[] = [];

        for (const filter of filters) {

            const toFilter = cards
                .filter(x => !filter.shouldBeIncluded(x)).map(x => x.name)
                .sort((a, b) => a > b ? 1 : -1);

            this.log(`${filter.name}: filtered ${toFilter.join(", ")}`);

            filteredCards.push(...toFilter);
        }

        filteredCards = [...new Set(filteredCards)];

        const cardsToUse = cards.filter(x => !filteredCards.includes(x.name));

        const filteredText = ` (${filteredCards.length} filtered)`;
        this.log(`Using ${cardsToUse.length} cards${filteredCards.length > 0 ? filteredText : ""}`);

        return cardsToUse;
    }

    private log(text: string) {
        this._logs.push(text);
    }
}