import { Card } from "../../Entitites/Card";
import { require_Attack } from "./Implementations/RequireAttack";
import { require_Trashing } from "./Implementations/Require_Trashing";

export type Require = {
    name: string,
    canSatisfy: (card: Card) => boolean
}

function buildRequire(query: string, min: number): Require {

    const name = `Require +${min} ${query}`;

    return {
        name,
        canSatisfy: (card: Card) => {

            const regex = new RegExp("\\+([0-9]) " + query);
            const match = regex.exec(card.text);

            //console.log({ card: card.name, text: card.text, match });

            if (match !== undefined && match !== null && match!.length > 0) {
                const num = Number(match?.[1]);

                if (!isNaN(num)) {

                    return num >= min;
                }
            }

            return false;
        }
    }
}

export const requires: Require[] = [
    buildRequire("Actions", 2),
    buildRequire("Cards", 2),
    buildRequire("Buy", 1),
    require_Trashing,
    require_Attack
]